import * as Sentry from '@sentry/astro';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  enabled: import.meta.env.NODE_ENV === 'production',
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  environment: import.meta.env.PUBLIC_SENTRY_PROJECT,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.05,
  blacklistUrls: [
    /chrome-extension/i,
    /safari-web-extension/i,
    /graph\.facebook\.com/i,
    /connect\.facebook\.net/i,
    /www\.googletagmanager\.com/i,
    /www\.googleadservices\.com/i,
    /googleads\.g\.doubleclick\.net/i,
    /analytics\.tiktok\.com/i,
    /sc-static\.net/i,
    /www\.redditstatic\.com/i
  ]
});
